import "./App.css";
import Login from "./pages/Login";
import TelaCursos from "./pages/Cursos";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import Cadastro from "./pages/Cadastro";
import Aula from "./pages/Aula";
import AppLayoutNoSidebar from "./components/layout/AppLayoutNoSidebar";
import { AuthProvider } from "./context/auth";
import { CourseProvider } from "./context/CourseContext";
import CadastroEmpresas from "./pages/CadastroEmpresas";
import CadastroPadrinho from "./pages/CadastroPadrinho";
import Payments from "./pages/Payments";
import ConsumedCredits from "./pages/ConsumedCredits";
import PasswordReset from "./pages/PasswordResets";
import ConfirmPassword from "./pages/ConfirmPassword";
import Terms from "./pages/StudentTerms";
import ChangePassword from "./pages/ChangePassword";
import CompanyTerms from "./pages/CompanyTerms";
import CompanyAppLayout from "./components/layout/CompanyAppLayout";
import CompanyEmployees from "./pages/CompanyEmployees";
import MySkills from "./pages/MySkills";
import ListClasses from "./pages/ListClasses";
import Trilhas from "./pages/Trilhas";
import CareerCourses from "./pages/TrilhasCourse";
import LandingPageTalent from "./pages/LandinPageTalent";
import { SubscriptionProvider } from "./context/Subscription";
import SeekTalent from "./pages/SeekTalent";
import ListInterviews from "./pages/ListInterviews";
import ListInterviewsCompany from "./pages/ListInterviewsCompany";
import ProfileStudent from "./pages/ProfileStudent";
import CodingPage from "./pages/CodingPage";
import MyMetrics from "./pages/MyMetrics";
import ProfileRecruiter from "./pages/ProfileRecruiter";
import StudentCollaboration from "./pages/StudentCollaboration";

function App() {
  return (
    <AuthProvider>
      <CourseProvider>
        <SubscriptionProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<AppLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/aula" element={<Aula />} />
              </Route>

              <Route element={<CompanyAppLayout />}>
                <Route path="/consumedCredits" element={<ConsumedCredits />} />
                <Route path="/profile-recruiter" element={<ProfileRecruiter />} />
                <Route path="/list-interviews-company" element={<ListInterviewsCompany />} />
                <Route path="/payments/:id" element={<Payments />} />
                <Route path="/companyEmployees" element={<CompanyEmployees />} />
                <Route path="/seek-talent" element={<SeekTalent />} />
              </Route>

              <Route element={<AppLayoutNoSidebar />}>
                <Route path="/coding-page" element={<CodingPage />} />
                <Route path="/profile-student" element={<ProfileStudent />} />
                <Route path="/list-classes" element={<ListClasses />} />
                <Route path="/cursos" element={<TelaCursos />} />
                <Route path="/trilhas" element={<Trilhas />} />
                <Route path="/career-courses" element={<CareerCourses />} />
                <Route path="/minhas-skills" element={<MySkills />} />
                <Route path="/minhas-metrics" element={<MyMetrics />} />
                <Route path="/student-collaboration" element={<StudentCollaboration />} />
                <Route path="/list-interviews" element={<ListInterviews />} />
                <Route path="/alterar-senha" element={<ChangePassword />} />
              </Route>

              {/* Rota pública */}
              <Route index path="/" element={<Login />} />
              <Route path="/cadastro" element={<Cadastro />} />
              <Route path="/cadastroEmpresas" element={<CadastroEmpresas />} />
              <Route path="/cadastroPadrinho" element={<CadastroPadrinho />} />
              <Route path="/passwordReset" element={<PasswordReset />} />
              <Route path="/confirmPassword" element={<ConfirmPassword />} />
              <Route path="/termos-de-uso" element={<Terms />} />
              <Route path="/termos-de-uso-empresa" element={<CompanyTerms />} />
            </Routes>
          </BrowserRouter>
        </SubscriptionProvider>
      </CourseProvider>
    </AuthProvider>
  );
}

export default App;
