import Axios from "../shared/axios";

class PasswordResetService {
  async create(data: any) {
    let token = localStorage.getItem("TOKEN");
    return Axios.post("password-resets/send-reset-email", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async updatePassword(data: any) {
   
    return Axios.post("password-resets/change-password", data)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

const passwordResetService = new PasswordResetService();
export default passwordResetService;
