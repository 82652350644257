import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import studentsService from "../../services/StudentsService";
import { Navigate, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

interface IMetrics {
  totalHours: number;
  totalMinutes: number;
  totalCompletedLessons: number;
  totalIncompleteLessons: number;
  completionRate: number;
  totalCoursesCompleted: number;
  totalCoursesStarted: number;
  averageTimePerWeek: number;
  averageTimePerDay: number;
  averageTimePerMonth: number;
  totalQuestionsAnswered: number;
  totalCorrectAnswers: number;
  totalWrongAnswers: number;
  correctAnswerRate: number;
  totalQuestionsSent: number;
  totalCommentsSent: number;
}

function MyMetrics() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [studentMetrics, setMetrics] = useState<IMetrics | null>(null);
  const navigate = useNavigate();

  const loadMetrics = () => {
    setLoading(true);
    studentsService
      .getMetrics()
      .then((response) => {
        setLoading(false);
        setMetrics(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    loadMetrics();
  }, []);

  return (
    <>
      <nav className="breadcrumb-talent" >
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li style={{ marginLeft: "10px" }}>
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>
          <li>
            <a className="p_normal" href="">
              Minhas Métricas
            </a>
          </li>
        </ol>
      </nav>

      <div className="space"></div>
      <div className="my-skills-container">
        <div className="containerWhite">
          <h2 className="title">Minhas Métricas</h2>
          <div className="space"></div>
          <Table hover size="sm" className="my-skills">
            <thead>
              <tr>
                <th>Métrica</th>
                <th>Valores</th>
              </tr>
            </thead>

            {isLoading && (
              <img
                src="/assets/images/loading_II.gif"
                className="icon-loading"
                alt="carregando"
              />
            )}

            <tbody>
              {studentMetrics && (
                <>
                  <tr>
                    <td>Total de Horas Assistidas</td>
                    <td>{studentMetrics.totalHours} horas</td>
                  </tr>
                  {/* <tr>
                    <td>Total de Minutos</td>
                    <td>{studentMetrics.totalMinutes}</td>
                  </tr> */}
                  <tr>
                    <td>Lições Completas</td>
                    <td>{studentMetrics.totalCompletedLessons}</td>
                  </tr>
                  <tr>
                    <td>Lições Incompletas</td>
                    <td>{studentMetrics.totalIncompleteLessons}</td>
                  </tr>
                  <tr>
                    <td>Taxa de Conclusão</td>
                    <td>{studentMetrics.completionRate.toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td>Cursos Completos</td>
                    <td>{studentMetrics.totalCoursesCompleted}</td>
                  </tr>
                  <tr>
                    <td>Cursos Iniciados</td>
                    <td>{studentMetrics.totalCoursesStarted}</td>
                  </tr>
                  <tr>
                    <td>Tempo Médio por Semana</td>
                    <td>{studentMetrics.averageTimePerWeek} horas</td>
                  </tr>
                  <tr>
                    <td>Tempo Médio por Dia</td>
                    <td>{studentMetrics.averageTimePerDay.toFixed(2)} horas</td>
                  </tr>
                  <tr>
                    <td>Tempo Médio por Mês</td>
                    <td>{studentMetrics.averageTimePerMonth} horas</td>
                  </tr>
                  <tr>
                    <td>Questões Respondidas</td>
                    <td>{studentMetrics.totalQuestionsAnswered}</td>
                  </tr>
                  <tr>
                    <td>Respostas Corretas</td>
                    <td>{studentMetrics.totalCorrectAnswers}</td>
                  </tr>
                  <tr>
                    <td>Respostas Incorretas</td>
                    <td>{studentMetrics.totalWrongAnswers}</td>
                  </tr>
                  <tr>
                    <td>Taxa de Acerto</td>
                    <td>{studentMetrics.correctAnswerRate.toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td>Questões Enviadas</td>
                    <td>{studentMetrics.totalQuestionsSent}</td>
                  </tr>
                  <tr>
                    <td>Comentários Enviados</td>
                    <td>{studentMetrics.totalCommentsSent}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default MyMetrics;
