import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Table from "react-bootstrap/Table";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import interviewService from "../../services/InterviewService";
import { IInterview } from "../../interfaces/IInterview";
import swal from "sweetalert";
import IStudentSkill from "../../interfaces/IStudentSkill";
import { FaHome } from "react-icons/fa";
import Swal from 'sweetalert2';

function ListInterviews() {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState<boolean>(true);

  const [interviews, setInterviews] = useState<IInterview[]>([]);

  const [students, setStudents] = useState<IStudentSkill[]>([]);

  const goMyCourses = () => {
    navigate("/cursos");
  };

  useEffect(() => {
    interviewService.getByUser().then((response) => {
      setInterviews(response.data);
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    });
  }, []);

  const toggleInterview = (idInterview: number, type: "Y" | "N") => {
    const data = {
      accepted: type,
    };
  
    Swal.fire({
      title: `Realmente deseja ${type === "Y" ? "aceitar" : "recusar"} essa entrevista?`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#f44336',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        interviewService
          .toggleInterview(idInterview, data)
          .then(() => {
            interviewService.getByUser().then((response) => {
              setInterviews(response.data);
              Swal.fire({
                icon: 'success',
                title: 'Status alterado com sucesso!',
              });
            });
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possível aceitar/recusar essa entrevista!',
            });
          });
      }
    });
  };

  return (
    <>
    <nav className="breadcrumb-talent" >
    <ol style={{ display: "flex", alignItems: "center" }}>
      <li style={{ marginLeft: "10px" }}>
        <FaHome style={{ marginRight: "5px" }} />
        <a className="p_normal" href="/cursos">
          Início
        </a>
      </li>
      <li>
        <a className="p_normal" href="/list-interviews">
          Minhas entrevistas
        </a>
      </li>           
    </ol>
  </nav>
    <div className="my-interviews-container " >
       <div className="containerWhiteII">
      
      <h2 className="title">Empresas interessadas em seu perfil.</h2>
      <div className="space"></div>
      <Table hover size="sm" className="my-interviews">
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Email</th>
            <th>Status da entrevista</th>
          </tr>
        </thead>
        { isLoading && <img src="/assets/images/loading_II.gif" className="icon-loading" alt="carregando" />}
        { interviews.length > 0 &&
          <tbody>
            {interviews.map((row, index) => (
              <tr key={index}>
                <td>{row.company.fantasy_name}</td>
                <td>{row.company.user.email}</td>
                <td
                  style={{
                    cursor:
                      row.accepted === null || row.accepted === "Y"
                        ? "pointer"
                        : "auto",
                  }}
                  onClick={() => {
                    if (row.accepted === null) {
                      toggleInterview(row.id, "Y");
                    } else if (row.accepted === "Y") {
                      toggleInterview(row.id, "N");
                    }
                  }}
                >
                  <Button
                    style={{
                      borderRadius: "10%",
                      width: "100px",
                      height: "30px",
                      padding: 0,
                      backgroundColor:
                        row.accepted === "Y"
                          ? "rgba(0, 128, 0, 0.3)"
                          : row.accepted === null
                          ? "rgba(255, 255, 0, 0.5)"
                          : row.accepted === "N"
                          ? "rgba(235, 37, 37, 0.5)"
                          : "inherit",
                    }}
                  >
                    {row.accepted === null
                      ? "Aguardando"
                      : row.accepted === "Y"
                      ? "Confirmado"
                      : "Cancelado"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody> 
        }
        { interviews.length === 0 && !isLoading && <p style={{padding: 10}}>Nenhuma entrevista até o momento.</p>}
      </Table>
      </div>
    </div>
    </>
  );
}

export default ListInterviews;
