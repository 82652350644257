import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { InputAdornment, Icon, OutlinedInput, IconButton } from "@material-ui/core";
import { Lock, Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import SubmitForm from "../Login/SubmitForm";
import passwordResetService from "../../services/PasswordReset";

// Aqui existe um tipo(IValuesForm), o objeto que vai ser enviado para o back.
//Por convenção deverá ter sempre o I maisculo quando fizer uma interface.
interface IValuesForm {
  password: string;
  confPassword: string;
  hash: string;
}

function ConfirmPassword() {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [hash, setHash] = useState(searchParams.get("hash"));

  const [type, setType] = useState<"password" | "text">("password");
  const [confPasswordType, setConfPasswordType] = useState<"password" | "text">(
    "password"
  );
  const [newPasswordType, setNewPasswordType] = useState<"password" | "text">(
    "password"
  );
  
  const cancel = () => {
    navigate("/");
  };
  //abaixo é a validação do formik, com preenchimento de valores pelo usuario.
  // na linha 58 (as IValuesForm) estaos dizendo que esse objeto é do tipo initialValues, se o valor declarado é numerico"number", deverá ser preenchido com
  //numero, se o valor declarado for uma "string", deverá ser com aspas vazia.
  // O initialValues indica os valores ou string, que o que o usuario verá quando abrir a pagina.
  const formik = useFormik({
    initialValues: {
      password: "",
      confPassword: "",
    } as IValuesForm,

    validationSchema: Yup.object({
      password: Yup.string().required("Digite uma nova senha"),
      confPassword: Yup.string()
        .required("Digite a senha novamente")
        .oneOf([Yup.ref("password")], "Senhas devem ser iguais"),
    }),

    //quando for enviado o formulario, irá imprimir os valores do formulario.
    onSubmit: (values) => {
      // o create é uma função e essa variavel 'values' possue valores do formulario, essa variavel 'values' possue os
      //valores das linhas 48 a 53.
      create(values);
    },
  });

  //const create é uma função chamada, quando se clik no botão para salvar
  // Na variavel values pode estar recebendo 'any' (aceita quarquer coisa ou valor), mas nesse caso aqui, essa variavel so pode aceitar,
  //o tipo IValuesForm.
  // Observar que quando no codar, o VS oferece o preenchimento do valor da variavel.

  const create = (values: IValuesForm) => {
    let data = {
      password: values.password,
      confPassword: values.confPassword,
      hash: hash,
    };
    
    
    passwordResetService      
      .updatePassword(data)      
      .then((response) => {
       // console.log('chegou aqui',data)
        setLoading(false);
        swal({
          title: "Senha alterada com sucesso!",
          text: "Você será redirecionado a página de login.",
          icon: "success",         
        }).then((value: any) => {
          console.log(value);
          navigate("/");
          //setLoading(false)
        });
      })
      .catch((error) => {
        swal({
          title: "Erro ao alterar a senha!",
          text: error.response?.data?.erroMessage,
          icon: "error",
          });

        }).then((value: any) => {
          setLoading(false);
        });   
     
  };

 

  return (
    <div className="container1 bg">
      <div
        className={
          !isSubmitSuccess ? "signin signin_wrapper" : "signin signin_success"
        }
      >
        {isSubmitSuccess ? (
          <SubmitForm />
        ) : (
          // este form é uma tag no HTML.
          // onSubmit é um evento que fica esperando uma ação do usuário e chama o {formik.handleSubmit}
          // e quando declaramos o formik, se define o que vai acontecer no submit
          <form onSubmit={formik.handleSubmit}>
            <div className="logo">
              {/* <img src="assets/images/logo_IV.png" alt="fatecoins" /> */}
            </div>
            <h2 className="title textCenter">Criar uma nova senha</h2>
            <br />
            <h5 className="lbl_bold">
              Digite uma nova senha
            </h5>
            <OutlinedInput
              required
              name={"password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (newPasswordType === "text") {
                        setNewPasswordType("password");
                      } else {
                        setNewPasswordType("text");
                      }
                    }}
                    edge="end"
                  >
                    {newPasswordType === "text" ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              type={newPasswordType}
              placeholder="Nova senha"
              className="textField"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <div className="space"></div>
            {formik.touched.password && formik.errors.password ? (
              <div className="error_msg">{formik.errors.password}</div>
            ) : null}
            <h5 className="lbl_bold">
              Confirme a nova senha
            </h5>
            <OutlinedInput
              required
              name={"confPassword"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      if (type === "text") {
                        setType("password");
                      } else {
                        setType("text");
                      }
                    }}
                    edge="end"
                  >
                    {type === "text" ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              type={type}
              placeholder="Confirme nova senha"
              className="textField"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confPassword}
            />
            {formik.touched.confPassword && formik.errors.confPassword ? (
              <div className="error_msg">{formik.errors.confPassword}</div>
            ) : null}
          <div className="space"></div>
            <button className="btn_primary full" type="submit">
              Recuperar Senha
            </button>
            <div className="space"></div>
            <div className="central-button">
            <button className="btn_tertiary" onClick={cancel}>
                Cancelar
              </button>
              </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default ConfirmPassword;
