import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useContext } from "react";
import "./index.scss";
import { ICourse, ICourseByCareer } from "../../interfaces/ICourse";
import { useLocation, useNavigate } from "react-router-dom";
import courseService from "../../services/CourseService";
import AuthContext from "../../context/auth";
import CourseContext from "../../context/CourseContext";
import ProgressBar from "@ramonak/react-progress-bar";
import { FaHome } from "react-icons/fa";

export default function Trilhas() {
  const location = useLocation();
  const navigate = useNavigate();

  const [courses, setCourses] = useState<ICourse[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [coursesFiltered, setCoursesFiltered] = useState<ICourseByCareer[]>([]);
  const [idCareer, setIdCareer] = useState<number>(location?.state?.idCareer);

  const { signed } = useContext(AuthContext);
  const { selectedCourse, markAsSelectedCourse, markAsNotSelectedCourse } =
    useContext(CourseContext);

  const loadList = () => {
    setLoading(true);
    console.log("Carregando cursos para a carreira com ID:", idCareer);

    courseService
      .getCoursesByCareer(idCareer)
      .then((result) => {
        console.log("Cursos carregados:", result);
        setCoursesFiltered(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao carregar a lista de cursos:", error);
      });
  };

  useEffect(() => {
    if (idCareer) {
      loadList();
    }
  }, [idCareer]);

  const access = (course: ICourse) => {
    let data: any = { id_course: course.id };

    courseService
      .start(data)
      .then((result: any) => {
        markAsSelectedCourse(course);
        navigate("/aula", {
          state: { course },
        });
      })
      .catch((error: any) => {
        console.error("Erro ao iniciar/continuar o curso:", error);
        alert("Não foi possível iniciar/continuar o curso");
      });
  };

  // Agrupar cursos por carreira
  const groupedCourses = coursesFiltered.reduce(
    (acc: any, item: ICourseByCareer) => {
      const careerId = item.careerPath?.id;
      if (!acc[careerId]) {
        acc[careerId] = {
          careerTitle: item.careerPath?.title,
          courses: [],
        };
      }
      acc[careerId].courses.push(item);
      return acc;
    },
    {}
  );

  return (
    <div className="App">
      <nav className="breadcrumb-talent" style={{ marginTop: 0 }}>
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li style={{ marginLeft: "10px" }}>
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>
          <li>
            <a className="p_normal" href="">
              Trilhas
            </a>
          </li>
        </ol>
      </nav>
      <main className="container">
        {Object.keys(groupedCourses).length === 0 && !isLoading && (
          <p className="">Nenhum curso encontrado</p>
        )}

        {isLoading && <p>Carregando...</p>}

        <div className="courses-container">
          {Object.values(groupedCourses).map(
            (group: any, groupIndex: number) => (
              <div key={groupIndex}>
                <div className="row justify-content-center">
                  {group.courses.map(
                    (item: ICourseByCareer, courseIndex: number) => {
                      const percentage = item.course.percentage || 0;
                      const courseNumber = groupIndex * 100 + courseIndex + 1;

                      return (
                        <div
                          className="col-md-4 mb-4 d-flex justify-content-center"
                          key={item.id}
                        >
                          <div
                            className="courses-trilhas"
                            style={{ maxWidth: "70%", display: "flex" }}
                          >
                            <div
                              className="card"
                              style={{ width: "100%", minHeight: "250px",  border: '1px solid rgba(0, 0, 0, 0.1)' }}
                            >
                             
                              {/* Altura mínima reduzida */}
                              <div className="course-image">
                                <img
                                  src={item.course.image}
                                  alt={item.course.name}
                                  className="img-fluid"
                                />
                                <div
                                  className="course-number"
                                  style={{
                                    backgroundColor:
                                      percentage >= 100 ? "green" : "#ffc107",
                                  }}
                                >
                                  <span>{courseNumber}</span>
                                </div>
                              </div>
                              <div
                                className="card-body"
                                style={{ padding: "15px" }}
                              >
                               
                                {/* Reduzi o padding */}
                                <h5 className="card-title text-center" >
                                  {item.course.name}
                                </h5>
                                <div className="progresso my-3" >
                                  <ProgressBar  
                                    completed={Math.round(percentage)}
                                    
                                    bgColor={
                                      percentage >= 100 ? "green" : "#f1a900"
                                    }
                                    baseBgColor="#e0e0de"
                                    height="12px" 
                                     //labelColor="rgba(0, 0, 0, 0.5)"
                                   
                                  />
                                </div>
                                <button
                                  className={`btn_primary ${
                                    percentage === 0
                                      ? "btn_primary"
                                      : "btn_secondary"
                                  } w-100`}
                                  onClick={() => access(item.course)}
                                >
                                  {percentage === 0
                                    ? "Iniciar curso"
                                    : percentage >= 100
                                    ? "Rever curso"
                                    : "Continuar curso"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </main>
    </div>
  );
}
