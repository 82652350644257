import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import studentsService from "../../services/StudentsService";
import { Navigate, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

interface ISkills {
  id: number;
  name: string;
  description: string;
  type: string;
  total: number;
}

function MySkills() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [studentSkills, setSkills] = useState<ISkills[]>([]);
  const navigate = useNavigate();

  const loadList = () => {
    setLoading(true);
    studentsService
      .getSkills()
      .then((response) => {
        setLoading(false);
        setSkills(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
    
    <nav className="breadcrumb-talent" >
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li style={{ marginLeft: "10px" }}>
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>        
          <li>
            <a className="p_normal" href="">
              Minhas skills
            </a>
          </li>
        </ol>
      </nav>
    
    <div className="space"></div>
    <div className="my-skills-container">      
      <div className="containerWhite">
        <h2 className='title'>Minhas Skills</h2>
        <div className="space"></div>
        <Table hover size="sm" className="my-skills">
          <thead>
            <tr>
              <th>Skill</th>
              <th>Descrição</th>
              <th>Tipo</th>
              <th>Experiência</th>
            </tr>
          </thead>

          { isLoading && <img src="/assets/images/loading_II.gif" className="icon-loading" alt="carregando" />}

          <tbody>
            {studentSkills.map((skill: ISkills) => (
              <tr
                key={skill.id}
              >
                <td>{skill.name}</td>
                <td>{skill.description}</td>
                <td>{(skill.type == "SOFT" ? "Soft Skill" : "Hard Skill") }</td>
                <td>{skill.total}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
    </>
  );
}

export default MySkills;
