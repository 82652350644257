import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import Table from "react-bootstrap/Table";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useContext, useEffect, useState } from "react";
import lessonService from "../../services/LessonService";
import { useNavigate } from "react-router-dom";
import { ICourse } from "../../interfaces/ICourse";
import courseService from "../../services/CourseService";
import CourseContext from "../../context/CourseContext";
import { FaHome } from "react-icons/fa";

interface IComplete {
    idLesson?: number;
    nameLesson: string;
    complete: "Y" | "N";
    totalQuestions: number;
    answersQuestions: number;
    correctQuestions: number;
    percentage: number;
}

function ListClasses() {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [complete, setComplete] = useState<IComplete[]>([]);
    const [coursesFiltered, setCoursesFiltered] = useState<ICourse[]>([]);
    const { selectedCourse } = useContext(CourseContext); 

    useEffect(() => {   
        LoadComplete();   
    }, [selectedCourse]);

    const LoadComplete = () => {
        setLoading(true);    
        if (selectedCourse) {
            lessonService
                .getDataByCourseStudent(selectedCourse.id)
                .then((result) => {
                    setComplete(result.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        }
    };

    const loadList = () => {
        setLoading(true);
        courseService
            .getAll()
            .then((result) => {
                setCoursesFiltered(result.data.courses);       
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                console.log("Não foi possível carregar a lista de aulas");
            });
    };  

    useEffect(() => {
        loadList();    
    }, []);

    const goToLesson = () => {
        navigate("/aula", {
            state: {
                course: selectedCourse,
            },
        });
    };

    const goMyCourses = () => {
        navigate("/cursos");
    };

    const getPercentageColor = (percentage: number) => {
      if (percentage === 100) return "#090"; // Verde para 100%
      if (percentage === 0) return "#ccc"; // Cinza para 0%
      
      return ""; // Cor padrão para outros valores
  };

    return (
      <div className="list-class" style={{ flexDirection: 'column', width: '70%', margin: 'auto', marginTop: '80px' }}>
      <div className="containerWhite">
          <nav className="breadcrumb-talent" style={{ marginTop: 0 }}>
              <ol>
                  <li style={{ marginLeft: "10px" }}>
                      <FaHome style={{ marginRight: "5px" }} />
                      <a className="p_normal" href="javascript: void(0)" onClick={goMyCourses}>
                          Início
                      </a>
                  </li>
                  <li>
                      <a className="p_normal" href="/cursos">
                          Meus cursos
                      </a>
                  </li>
                  <li>
                      <a className="p_normal" href="javascript: void(0)" onClick={goToLesson}>
                          <span>{selectedCourse?.name}</span>
                      </a>
                  </li>
              </ol>
          </nav>
          <Table hover size="sm" className="my-skills" style={{ marginTop: '20px', marginBottom: '15%' }}>
              <thead>
                  <tr>
                      <th>Aula</th>
                      <th>Assistida</th>
                      <th>Questões respondidas</th>
                      <th>Aproveitamento</th>
                  </tr>
              </thead>

              {isLoading && <img src="/assets/images/loading.gif" className="icon-loading" width={50} alt="carregando" />}

              <tbody>
                  {complete?.map((item: IComplete) => (
                      <tr key={item.idLesson}>
                          <td>{item.nameLesson}</td>
                          <td className="center">
                              {item.complete === "Y" ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                          </td>
                          <td className="center">
                              {item.answersQuestions === item.totalQuestions && item.totalQuestions > 0 ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                          </td>
                          <td className="center">
                              {item.totalQuestions > 0 && (
                                  <span style={{ color: getPercentageColor(item.percentage), fontWeight: "bold" }}>
                                      {item.percentage} %
                                  </span>
                              )}
                          </td>
                      </tr>
                  ))}
              </tbody>
          </Table>
      </div>
  </div>
    );
}

export default ListClasses;
