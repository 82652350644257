import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect, useContext } from "react";
import "./index.scss";
import { Grid } from "@material-ui/core";
import Container from "@mui/material/Container";
import { FaHome } from "react-icons/fa";
import Swal from "sweetalert2";
import Form from 'react-bootstrap/Form';
import { Col, Row } from "react-bootstrap";
import TabsSkillsInterviews from "../../components/TabsSkillsInterviews/TabsSkillsInterviews";
import usersService from "../../services/UsersService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ImagePicker } from "../../components/ImagePicker";
import AuthContext from "../../context/auth";
import { useNavigate } from "react-router-dom";
import { UpdateUserDto } from "../../interfaces/IUser";

// interface ISkills {
//   id: number;
//   name: string;
//   description: string;
//   type: string;
//   total: number;
// }

interface IUser {
  id: number;
  name: string;
  email: string;
  password: string;
  role: string;
  profileImage: string | null;
  companies: [{
    fantasy_name: string | null;
    official_name: string;
    official_document: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    website: string | null;
    linkedin: string;
  }];
}

function ProfileRecruiter() {
  const { markAsNotSigned, reloadLocalStorage } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dataUser, setDataUser] = useState<IUser>({} as IUser);
  const [editable, setEditable] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [urlPhotoProfile, setUrlPhotoProfile] = useState<string | null>(null);
  const [removePhotoProfile, setRemovePhotoProfile] = useState<boolean>(false);
  const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false); // Novo estado para controlar o botão "Enviar"

  const removePhoto = async () => {
    await usersService.removePhotoProfile().finally(() => {
      getDataUser();
      setUrlPhotoProfile(null);
      setIsSendDisabled(false); // Reabilita o botão "Enviar" após a remoção da imagem
      saveDataProfile();
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      company: {
        fantasy_name: "",
        official_name: "",
        official_document: "",
        city: "",
        state: "",
        country: "",
        website: "",
        linkedin: "",
      },
      confPassword: "",
    },

    validationSchema: Yup.object({
      password: Yup.string().min(6, "Senha deve ter no mínimo 6 caracteres"),
      confPassword: Yup.string().oneOf([Yup.ref("password")], "Senha devem ser iguais"),
    }),

    onSubmit: async (values) => {
      let data: UpdateUserDto = {
        name: values.name,
        email: values.email,
        password: values.password,
        company: {
          fantasy_name: values.company.fantasy_name,
          official_name: values.company.official_name,
          official_document: values.company.official_document,
          city: values.company.city,
          state: values.company.state,
          country: values.company.country,
          website: values.company.website,
          linkedin: values.company.linkedin,
        },
        profileImage: removePhotoProfile ? null : dataUser.profileImage,
      };

      await usersService.updateDataProfile(data).then(async (response) => {
        if (response.status) {
          if (values.email !== dataUser.email) {
            logout();
          }

          getDataUser();
          setRemovePhotoProfile(false);
        }
      });
    },
  });

  const logout = () => {
    localStorage.removeItem("TOKEN");
    markAsNotSigned();
    navigate("/");
  };

  const getDataUser = async () => {
    await usersService.getByTokenCompany().then((response) => {
      let dataUser: IUser = response.data;
      formik.values.name = dataUser.name;
      formik.values.email = dataUser.email;
      formik.values.company.fantasy_name = dataUser.companies[0].fantasy_name ? dataUser.companies[0].fantasy_name : '';
      formik.values.company.official_name = dataUser.companies[0].official_name ? dataUser.companies[0].official_name : '';
      formik.values.company.official_document = dataUser.companies[0].official_document ? dataUser.companies[0].official_document : '';
      formik.values.company.city = dataUser.companies[0].city ? dataUser.companies[0].city : '';
      formik.values.company.state = dataUser.companies[0].state ? dataUser.companies[0].state : '';
      formik.values.company.country = dataUser.companies[0].country ? dataUser.companies[0].country : '';
      formik.values.company.website = dataUser.companies[0].website ? dataUser.companies[0].website : '';
      formik.values.company.linkedin = dataUser.companies[0].linkedin ? dataUser.companies[0].linkedin : '';

      if (dataUser.profileImage != null && dataUser.profileImage != "") {
        setUrlPhotoProfile(`https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${dataUser.profileImage}`);
      }

      let user = {
        name: dataUser.name,
        photoProfile: dataUser.profileImage,
        role: dataUser.role,
      };
      localStorage.removeItem("USER");
      localStorage.setItem("USER", JSON.stringify(user));
      console.log('DATAUSER', dataUser);
      setDataUser(dataUser);
    });
  };

  const handleFileChange = async (file: File | null) => {
    setSelectedFile(file);
    if (file) {
      setIsSendDisabled(true); // Desabilita o botão "Enviar" após o upload da imagem
      const response = await usersService.uploadPhotoProfile(file);
      if (response.status) {
        console.log("Imagem carregada com sucesso!");
      } else {
        console.error("Erro ao carregar a imagem:", response.message);
      }
      getDataUser(); // Recarrega os dados do usuário para atualizar a imagem do perfil
      saveDataProfile();
    }
  };

  const handleEditButton = () => {
    setEditable(true);
  };

  const saveDataProfile = () => {
    Swal.fire({
      icon: "success",
      title: "Parabéns!",
      text: "Dados salvos com sucesso!",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        setEditable(false);
        reloadLocalStorage();
      }
    });
  };

  useEffect(() => {
    console.log('DATAUSER', getDataUser());
    getDataUser();
  }, []);

  return (
    <Container>
      <nav className="breadcrumb-talent">
        <ol style={{ display: "flex", alignItems: "center" }}>
          <li style={{ marginLeft: "10px" }}>
            <FaHome style={{ marginRight: "5px" }} />
            <a className="p_normal" href="/cursos">
              Início
            </a>
          </li>
          <li>
            <a className="p_normal" href="">
              Meu perfil
            </a>
          </li>
        </ol>
      </nav>

      <div className="App">
        <div className="space"></div>
        <h2 className="title">Meu Perfil</h2>
        <div className="space"></div>

        <Grid className="container-profile">
          <div className="d-flex justify-content-center mt-4 mb-3 custom-image">
            <ImagePicker handleFileChange={handleFileChange} initialImage={urlPhotoProfile} />
          </div>

          <form className="form-dados" style={{ gap: '28px' }} onSubmit={formik.handleSubmit}>
            <div className="space"></div>

            <h2 className="title">Dados Pessoais</h2>

            <div className="d-flex gap-3">
              <div className="div-label">
                <span className="title-left bold label-dados">
                  Nome Fantasia
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="text" name="company.fantasy_name" value={formik.values.company.fantasy_name} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} required />
                </Form.Group>
                {formik.touched.company?.fantasy_name && formik.errors.company?.fantasy_name ? (
                  <div className="error_msg">{formik.errors.company?.fantasy_name}</div>
                ) : null}
              </div>

              <div className="div-label">
                <span className="title-left bold label-dados">
                  Razão Social
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="text" name="company.official_name" value={formik.values.company.official_name} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} required />
                </Form.Group>
                {formik.touched.company?.official_name && formik.errors.company?.official_name ? (
                  <div className="error_msg">{formik.errors.company?.official_name}</div>
                ) : null}
              </div>
            </div>

            <div className="space"></div>

            <div className="d-flex gap-3">
              <div className="div-label">
                <span className="title-left bold label-dados">
                  CNPJ
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="text" name="company.official_document" value={formik.values.company.official_document} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} />
                </Form.Group>
                {formik.touched.company?.official_document && formik.errors.company?.official_document ? (
                  <div className="error_msg">{formik.errors.company?.official_document}</div>
                ) : null}
              </div>

              <div className="div-label">
                <span className="title-left bold label-dados">
                  E-mail
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="email" name="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} required />
                </Form.Group>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error_msg">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="space"></div>

            <div className="d-flex gap-3">
              <div className="div-label">
                <span className="title-left bold label-dados">
                  Estado
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="text" name="company.state" value={formik.values.company.state} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} required />
                </Form.Group>
                {formik.touched.company?.state && formik.errors.company?.state ? (
                  <div className="error_msg">{formik.errors.company?.state}</div>
                ) : null}
              </div>

              <div className="div-label">
                <span className="title-left bold label-dados">
                  Cidade
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="text" name="company.city" value={formik.values.company.city} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} />
                </Form.Group>
                {formik.touched.company?.city && formik.errors.company?.city ? (
                  <div className="error_msg">{formik.errors.company?.city}</div>
                ) : null}
              </div>
            </div>

            <div className="space"></div>

            <div className="d-flex gap-3">
              <div className="div-label">
                <span className="title-left bold label-dados">
                  Perfil LinkedIn (opcional)
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="text" name="company.linkedin" value={formik.values.company.linkedin} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} />
                </Form.Group>
                {formik.touched.company?.linkedin && formik.errors.company?.linkedin ? (
                  <div className="error_msg">{formik.errors.company?.linkedin}</div>
                ) : null}
              </div>

              <div className="div-label">
                <span className="title-left bold label-dados">
                  Website (opcional)
                </span>
                <Form.Group className="mb-3 lg" controlId="formBasicPassword">
                  <Form.Control type="text" name="company.website" value={formik.values.company.website} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} />
                </Form.Group>
                {formik.touched.company?.website && formik.errors.company?.website ? (
                  <div className="error_msg">{formik.errors.company?.website}</div>
                ) : null}
              </div>
            </div>

            <div className="space"></div>

            <h2 className="title">Senha</h2>
            <div className="mlinputcontainer">
              <Row>
                <Col>
                  <span className="title-left bold label-dados">
                    Senha (Opcional)
                  </span>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="••••••" name="password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} />
                  </Form.Group>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error_msg">{formik.errors.password}</div>
                  ) : null}
                </Col>

                <Col>
                  <span className="title-left bold label-dados">
                    Confirme sua senha
                  </span>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control type="password" placeholder="••••••" name="confPassword" value={formik.values.confPassword} onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={!editable} />
                    {formik.touched.confPassword && formik.errors.confPassword ? (
                      <div className="error_msg">{formik.errors.confPassword}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="space"></div>

            <Container>
              {!editable ? (
                <button className="btn_primary" id="buttonEditar" type="button" onClick={() => { handleEditButton() }}>
                  Editar
                </button>
              ) : (
                <button className="btn_primary" id="buttonEditar" type="submit" onClick={() => { saveDataProfile() }} disabled={isSendDisabled}>
                  Salvar
                </button>
              )}
            </Container>
          </form>
        </Grid>
      </div>
    </Container>
  );
}

export default ProfileRecruiter;
